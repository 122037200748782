import React, { useState } from "react"
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Section } from "../components/section";
import { ContentWrapper } from "../components/common";
import SelectionPanel from "../components/SelectionPanel";
import ApartmentList from "../components/AppartmentList";
import imgSelectionSrc from '../images/img-selection-n.jpg';
import { FINANCE } from '../routes';
import data from '../../data.json';
import ApartmentDetail from "../components/ApartmentDetail";
import photo01Src from '../images/img-photo-detail-01-n.jpg';
import photo02Src from '../images/img-photo-detail-02-n.jpg';
import photo03Src from '../images/img-photo-detail-03-n.jpg';
import Contact from "../components/Contact";
import downloadSrc from '../images/svg/download.svg';
import arrowDownSrc from '../images/svg/arrow-down.svg';
import AtelierOffer from "../components/AtelierOffer";

const DATA = [
  {
    id: 1,
    path: 'm440,837h1086v125.78H440v-125.78Z',

  },
  {
    id: 2,
    path: 'm440,707.22h1086v129.78H440v-129.78Z',

  },
  {
    id: 3,
    path: 'm440,561.89h1086v145.33H440v-145.33Z',

  },
  {
    id: 4,
    path: 'm1526,408.11v153.78H440v-153.78h1086Z',

  }
];

const ArrowDownIcon = styled(arrowDownSrc)`
  fill: ${({theme}) => theme.colors.white};
  transition: fill 0.5s ease;
`;

const DownloadBtn = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration: none;


  span {
    display: block;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.white};
    padding: 1rem;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.primary};
    transition: all 0.5s ease;
  }

  &:hover {
    text-decoration: none;

    span {
      color: ${({theme}) => theme.colors.primary};
      background-color: transparent;
    }

  }
`;

const DownloadIcon = styled(downloadSrc)`
  width: 20px;
  height: auto;
  fill: #303030;
  margin-right: 1rem;

  /* &:hover { */
    fill: ${({theme}) => theme.colors.primary};
  /* } */
`;

const BtnsWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;

${DownloadBtn} {
  margin: 1rem 0rem;

  span {
    width: 100%;
  }
}

@media (min-width: 768px) {
  display: none;
  /* flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 1.5rem;

  ${DownloadBtn} {
    margin: 0 1rem;
  } */
  }
`;

const Path = styled.path``;

const ActionBtn = styled.a`
  position: absolute;
  bottom: 26px;
  left: 15px;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({theme}) => theme.colors.primary};
  /* border: 1px solid ${({theme}) => theme.colors.primary}; */
  transition: background-color 0.5s ease, color 0.5s ease;
  padding: 20px;
  width: 110px;
  gap: 14px;
  color: ${({theme}) => theme.colors.white};
  text-decoration: none;
  outline: 0;

  span {
    text-transform: uppercase;
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.white};

    ${ArrowDownIcon} {
      fill: ${({theme}) => theme.colors.primary};
    };
  }

  @media(min-width: 768px) {
    display: flex;
  }

  @media(min-width: 992px) {
    bottom: 60px;
  }

  @media(min-width: 1200px) {
    bottom: 130px;
  }
`;

const PickerTitle = styled.p`
`;
const Picker = styled.div`
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  ${Path} {
    opacity: 0;
    fill: ${({theme}) => theme.colors.primary};
    cursor: pointer;
    transition: all .5s ease;

    &.selected {
      opacity: 0.6;
    }
  }

  ${Path}:hover {
    opacity: 0.35;

    &.selected {
      opacity: 0.6;
    }
  }

  ${ContentWrapper} {
    display: none;

    @media(min-width: 768px) {
      display: block;
    }
  }

  ${PickerTitle} {
    font-family: 'Oswald', Georgia, sans-serif;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    /* width: 100%;
    max-width: 1920px; */
    text-align: center;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primary};

    @media(min-width: 576px) {
      top: 50px;
      font-size: 1.5rem;
    }

    @media(min-width: 768px) {
      top: 80px;
      font-size: 2rem;
      line-height: 2.25rem;
    }

    @media(min-width: 992px) {
      top: 140px;
      font-size: 2.25rem;
      line-height: 2.5rem;
    }

    @media(min-width: 1200px) {
      font-size: 2.25rem;
    }

    @media(min-width: 1400px) {
      top: 180px;
      font-size: 3rem;
      line-height: 3rem;
    }
  }
`;

const PinkSection = styled(Section)`
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  text-align: center;

  @media(min-width: 992px) {
    padding: 5.625rem 0 5.625rem 0;
  }
`;

const SectionTitle = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 3rem;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.white};
  margin-bottom: 1.rem;

  @media (min-width: 1200px) {
    font-size: 2.75rem;
    line-height: 4rem;;
  }
`;

const Text = styled.p`
  font-size: 1.125rem;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 2rem;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.875rem;
  text-transform: uppercase ;
  font-family: 'Oswald', Georgia, sans-serif;
    font-weight: 200;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.white};
  /* width: 100%; */
  padding: 0.575rem 2.25rem;
  transition: all .3s ease;
  /* border: 1px solid ${({theme}) => theme.colors.primary}; */
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    background-color:${({theme}) => theme.colors.white};
  }
`;


const FullSection = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;


const AboutProjectWrapper = styled.div`
  padding: 2.5rem 0 1rem 0;

  @media(min-width: 768px) {
    padding: 3.5rem 0 3.5rem 0;
  }

  @media(min-width: 992px) {
    width: 50%;
  }

  @media(min-width: 1200px) {
    padding: 60px 0 60px 0;
    width: 50%;
  }
`;

const Title = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.primary};
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  /* margin-bottom: rem; */
  text-align: left;

  @media(min-width: 1200px) {
    font-size: 2rem;
    line-height: 3rem;
    /* margin-bottom: 5rem; */
  }
`;

const PhotoPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0.25rem;
  row-gap: 0.25rem;
  background-color: #fff1ea;

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-direction: column-reverse; */

  @media(min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

function ApartmentSelectionPage () {
  const [selectedFloor, setSelectedFloor] = useState(1);
  const [selectedItem, setSelectedItem] = useState(data[0]);
  // const [isModalOpen, setModalOpen] = useState(false)

  return (
    <Layout hideContact={true}>
      <Seo title="Výběr bytu" />
      <AtelierOffer />
      <Picker>
        <PickerTitle>Klikněte na dům a&nbsp;vyberte si patro</PickerTitle>
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1200">
          <image href={imgSelectionSrc} alt="Foto - výběr bytu"></image>
            {
              DATA.map(({id, path}) => (
                <Path key={id} d={path} className={selectedFloor === id ? 'selected' : ''} onClick={() => setSelectedFloor(id)}/>
              ))
            }
          </svg>
        <ContentWrapper>
          <ActionBtn href={`/data/pudorys_1PP-062024.pdf`} title="Zobrazit v PDF" target="_blank" rel="noreferrer">
            <ArrowDownIcon />
            <span>1.&nbsp;PP</span>
          </ActionBtn>
        </ContentWrapper>
      </Picker>
      <FullSection>
       <ContentWrapper>
        <SelectionWrapper>
          <SelectionPanel selectedFloor={selectedFloor} selectedItem={selectedItem} onFloorSelect={id => setSelectedFloor(id)} onItemSelect={it => setSelectedItem(it)} />
            {/* <div className="row">
              <div className="col-xs-6">
              </div>
              <div className="col-xs-6 col-lg-6 offset-lg-6"> */}
                <AboutProjectWrapper>
                  <ApartmentList selectedFloor={selectedFloor} selectedItem={selectedItem} onItemSelect={it => setSelectedItem(it)} />
                  <BtnsWrapper>
                    <DownloadBtn href={`/data/pudorys_1PP-062024.pdf`} title="Zobrazit v PDF" target="_blank" rel="noreferrer">
                      <DownloadIcon />
                      <span>Podívejte se na podzemní patro v&nbsp;pdf</span>
                    </DownloadBtn>
                  </BtnsWrapper>
                </AboutProjectWrapper>
          </SelectionWrapper>
        </ContentWrapper>
      </FullSection>
      <ApartmentDetail item={selectedItem} />
        <PhotoPanel>
          <img src={photo01Src} alt="Foto" />
          <img src={photo02Src} alt="Foto" />
          <img src={photo03Src} alt="Foto" />
        </PhotoPanel>
      <PinkSection>
        <ContentWrapper>
          <SectionTitle>Potřebujete pomoc s&nbsp;financováním?</SectionTitle>
          <Text>Jsme tu od toho, abychom vám s&nbsp;nákupem bytu asistovali. Naši specialisté mají nespočet zkušeností a&nbsp;vyberou s&nbsp;vámi ten nejvýhodnější způsob.</Text>
          <BtnWrapper>
            <StyledLink to={FINANCE}>
              Zjistěte více o&nbsp;financování
            </StyledLink>
          </BtnWrapper>
        </ContentWrapper>
      </PinkSection>
      <Contact apartmentId={selectedItem.id} />
    </Layout>
  );
}

export default ApartmentSelectionPage;
